'use client';

import { faAngleDown, faCreditCard, faSignOut, faSquarePollVertical, faTableColumns, faUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Menu } from '@pickleballinc/react-ui';
import { MenuItem } from '@pickleballinc/react-ui/Menu/MenuItem';
import clsx from 'clsx';
import { usePathname, useRouter } from 'next/navigation';
import { User } from 'protos/model';
import React from 'react';

import { useAuth } from '@/permissions/AuthProvider';
import { PB_PERMISSIONS } from '@/permissions/permission';
import getCDNURL from '@/utils/getCDNURL';

interface UserProfileProps {
	user: User;
	isWhiteBackground?: boolean;
}

export const UserProfile = ({ user, isWhiteBackground = false }: UserProfileProps) => {
	const router = useRouter();
	const pathname = usePathname();
	const { hasPermission } = useAuth();
	const canAccessManage = hasPermission(PB_PERMISSIONS);

	const userNameClasses = clsx('ml-1 text-sm font-medium text-white', isWhiteBackground ? '!text-gray-700' : 'text-white');

	const profileImgArray = user && user.images ? user.images.filter((image: any) => image.type === 'profileImg') : [];
	const profileImgObj = profileImgArray[0];

	const showPaymentsLink =
		process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME === 'pickleballdev.net' || process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME?.startsWith('localhost');

	const profilePath = `/players/${user.slug || user.uuid}`;
	const paymentsPath = `${profilePath}/profile/payments`;
	const myPickleballPath = `${profilePath}/my-pickleball`;
	const managePath = `https://managev2.${process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME}`;

	const handleLogout = () => {
		let baseURL = window.location.href;
		if (pathname?.startsWith('/players/')) {
			baseURL = `${window.location.origin}/players`;
		}

		const redirectUrl = `${process.env.NEXT_PUBLIC_SSO_ENDPOINT}/sessions/revoke?continue=${encodeURIComponent(baseURL)}`;
		router.push(redirectUrl);
	};

	return (
		<Menu showArrow={false} offset={5} placement="bottom-end">
			<Menu.Trigger asChild>
				<div className="flex cursor-pointer items-center gap-1">
					<Avatar imageUrl={profileImgObj ? getCDNURL(profileImgObj.url, 24, 24) : ''} size="xs" />
					<span className={userNameClasses}>{user.firstName}</span>
					<FontAwesomeIcon icon={faAngleDown} size="xs" className={isWhiteBackground ? 'text-gray-700' : 'text-white'} />
				</div>
			</Menu.Trigger>
			<Menu.List className="w-56 rounded border-none">
				<div onClick={() => router.push(profilePath)} className="!outline-none">
					<MenuItem
						className="cursor-pointer rounded p-2 font-normal !outline-none hover:bg-gray-100"
						icon={<FontAwesomeIcon icon={faUser} size="sm" width="20" />}
					>
						My Profile
					</MenuItem>
				</div>
				<div onClick={() => router.push(myPickleballPath)} className="!outline-none">
					<MenuItem
						className="cursor-pointer rounded p-2 font-normal !outline-none hover:bg-gray-100"
						icon={<FontAwesomeIcon icon={faSquarePollVertical} size="sm" width="20" />}
					>
						My Pickleball
					</MenuItem>
				</div>
				{showPaymentsLink && (
					<div onClick={() => router.push(paymentsPath)} className="!outline-none">
						<MenuItem
							className="cursor-pointer rounded p-2 font-normal !outline-none hover:bg-gray-100"
							icon={<FontAwesomeIcon icon={faCreditCard} size="sm" width="20" />}
						>
							Payments
						</MenuItem>
					</div>
				)}
				{canAccessManage && (
					<div onClick={() => router.push(managePath)} className="!outline-none">
						<MenuItem
							className="cursor-pointer rounded p-2 font-normal !outline-none hover:bg-gray-100"
							icon={<FontAwesomeIcon icon={faTableColumns} size="sm" width="20" />}
						>
							Manage
						</MenuItem>
					</div>
				)}
				<MenuItem
					className="cursor-pointer rounded p-2 font-normal !outline-none hover:bg-gray-100"
					icon={<FontAwesomeIcon icon={faSignOut} size="sm" width="20" />}
					onClick={handleLogout}
				>
					Logout
				</MenuItem>
			</Menu.List>
		</Menu>
	);
};

import { BLOG_LABEL } from '@/utils/constants';

export const nav = [
	{
		id: '1',
		children: 'News',
		href: `/news/all`
	},
	{
		id: '2',
		children: 'Tournaments',
		href: process.env.NODE_ENV !== 'development' ? process.env.NEXT_PUBLIC_PT_URL || 'https://pickleballtournaments.com' : '/tournaments'
	},
	{
		id: '9',
		children: 'Results',
		href: `/results`
	},
	{
		id: '3',
		children: 'Leagues',
		href: `/leagues`
	},
	{
		id: '4',
		children: 'Clubs',
		href: `/clubs`
	},
	{
		id: '5',
		children: 'Players',
		href: `/players`
	},
	{
		id: '6',
		children: 'Standings',
		target: '_blank',
		href: 'https://www.ppatour.com/player-rankings/'
	},
	{
		id: '8',
		children: 'Tickets',
		target: '_blank',
		href: 'https://www.tixr.com/groups/ppa'
	},
	{
		id: '11',
		children: BLOG_LABEL,
		href: `/news/all`
	}
];

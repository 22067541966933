'use client';

import { faClose } from '@fortawesome/pro-light-svg-icons';
import { faCirclePlay } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { YouTubeEmbed } from '@next/third-parties/google';
import clsx from 'clsx';
import React from 'react';

import RenderIframe from '@/components/VideoCard/RenderIframe';
import styles from '@/components/VideoCard/VideoCard.module.css';
import getCDNURL from '@/utils/getCDNURL';

export interface VideoCardProps {
	image?: string;
	title?: string;
	videoURL?: string;
}

const VideoCard = ({ image, title, videoURL }: VideoCardProps) => {
	const wrapperClasses = clsx(styles.wrapper);
	const imageClasses = clsx(styles.image);
	const playIconContainerClasses = clsx(styles.play__icon__container);
	const playIconClasses = clsx(styles.play__icon);
	const iframeContainerClasses = clsx(styles.iframe__container);
	const iframeClasses = clsx(styles.iframe);
	const closeIconClasses = clsx(styles.close__icon);
	const iframe2ContainerClasses = clsx(styles.iframe2__container, {
		[styles['iframe2__container--title'] as string]: title,
		[styles['iframe2__container--no-title'] as string]: !title
	});
	const iframe2Classes = clsx(styles.iframe2);
	const contentClasses = clsx(styles.content);
	const titleClasses = clsx(styles.title);

	const [isVideoPlaying, setIsVideoPlaying] = React.useState<boolean>(false);

	const youtubeVideoId = videoURL?.includes('youtube') ? videoURL?.split('watch?v=')[1] : '';
	const vimeoVideoId = videoURL?.includes('vimeo') ? videoURL?.split('vimeo.com/')[1] : '';
	const youtubeLink = `https://youtube.com/embed/${videoURL?.includes('youtube') ? `${youtubeVideoId}?rel=0` : ''}`;
	const vimeoLink = `https://player.vimeo.com/video/${videoURL?.includes('vimeo') ? vimeoVideoId : ''}`;

	const embedLink = videoURL?.includes('youtube') ? youtubeLink : vimeoLink;

	return (
		<div className={wrapperClasses} itemScope itemType="https://schema.org/VideoObject">
			<meta itemProp="embedUrl" content={embedLink} />
			<meta itemProp="contentUrl" content={videoURL} />
			{image ? (
				!isVideoPlaying ? (
					<div
						style={{
							backgroundImage: `url("${getCDNURL(image, 640, 335) || `${getCDNURL(`${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleball-app/assets/images/placeholder.png`, 640, 640)}`}")`
						}}
						className={imageClasses}
						itemProp="thumbnail"
					>
						<div className={playIconContainerClasses} onClick={() => setIsVideoPlaying(true)}>
							<FontAwesomeIcon icon={faCirclePlay} className={playIconClasses} />
						</div>
					</div>
				) : (
					<div className={iframeContainerClasses}>
						{youtubeVideoId ? (
							<YouTubeEmbed videoid={youtubeVideoId} params="rel=0" />
						) : (
							<RenderIframe className={iframeClasses} title={title} src={embedLink} />
						)}

						<span className={closeIconClasses} onClick={() => setIsVideoPlaying(false)}>
							<FontAwesomeIcon size="sm" icon={faClose} />
						</span>
					</div>
				)
			) : (
				<div className={iframe2ContainerClasses}>
					{youtubeVideoId ? (
						<YouTubeEmbed videoid={youtubeVideoId} params="rel=0" />
					) : (
						<RenderIframe className={iframe2Classes} title={title} src={embedLink} />
					)}
				</div>
			)}
			{title && (
				<div className={contentClasses}>
					<h3 className={titleClasses} itemProp="caption">
						{title}
					</h3>
				</div>
			)}
		</div>
	);
};

export default VideoCard;

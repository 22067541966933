'use client';

import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import { VideoCard } from '../VideoCard';
import SkipIcon from './SkipIcon';

// This function is called on client only
async function fetchAndParseVAST(url: string): Promise<string> {
	try {
		// Fetch the VAST XML file
		const response = await fetch(url, {
			next: { revalidate: 60 }
		});
		const xmlText = await response.text();

		// Parse the XML
		const parser = new DOMParser();
		const xmlDoc: Document = parser.parseFromString(xmlText, 'text/xml');

		// Extract media file URL
		const mediaFile = xmlDoc.getElementsByTagName('MediaFile')[0];
		if (mediaFile && mediaFile.textContent) {
			const mediaFileURL = mediaFile.textContent.trim();

			return mediaFileURL;
		}

		return '';
	} catch (error) {
		console.error('Error fetching or parsing VAST file:', error);
		return '';
	}
}

export interface VideoAdProps {
	vastURL: string;
	image?: string;
	title?: string;
	videoURL?: string;
}

const VideoAd = ({ vastURL, image, title, videoURL }: VideoAdProps) => {
	const [videoAdURL, setVideoAdURL] = useState<string>('');
	const [videoAdPlaying, setVideoAdPlaying] = useState<boolean>(!videoURL);
	const [adWatchedOrSkipped, setAdWatchedOrSkipped] = useState<boolean>(false);
	const [isSkippable, setIsSkippable] = useState<boolean>(false);
	const [secondsLeft, setSecondsLeft] = useState<number>(3);

	const handleAdEnd = () => {
		setVideoAdPlaying(false);
		setAdWatchedOrSkipped(true);
		setSecondsLeft(0);
	};

	useEffect(() => {
		const getAndSetVideoAdURL = async (vastURL: string): Promise<void> => {
			const videoAdURL = await fetchAndParseVAST(vastURL);
			setVideoAdURL(videoAdURL);
		};

		getAndSetVideoAdURL(vastURL);
	}, [vastURL]);

	useEffect(() => {
		if (!isSkippable && secondsLeft > 0) {
			const interval = setInterval(() => {
				setSecondsLeft(secondsLeft - 1);
			}, 1000);

			return () => clearInterval(interval);
		}
		return () => {};
	}, [isSkippable, secondsLeft]);

	setTimeout(() => setIsSkippable(true), 3000);

	const overlayClasses = clsx('absolute left-0 top-0 h-40 w-full rounded-lg sm:h-80', {
		'h-48': image
	});
	const skipClasses = clsx('z-1000 pointer-events-auto absolute bottom-[44px] right-0 py-[15px] pl-[15px] pr-0 sm:bottom-[56px]');
	const skipButtonClasses = clsx(
		'pointer-events-auto m-0 box-content flex h-fit w-auto min-w-0 cursor-pointer items-center gap-1 border border-r-0 border-solid border-[rgba(255,255,255,0.5)] bg-[rgba(0,0,0,0.8)] py-1 pl-2 pr-1',
		{
			'hover:border-white': isSkippable,
			'opacity-60 cursor-default': !isSkippable
		}
	);

	return (
		<>
			{!adWatchedOrSkipped ? (
				<>
					{videoAdPlaying ? (
						<>
							{videoAdURL ? (
								<div className="relative">
									<video className="w-full" src={videoAdURL} autoPlay muted={!videoURL} onEnded={handleAdEnd} controls />
									<div className={skipClasses}>
										<button
											onClick={() => {
												if (!isSkippable) return;
												setVideoAdPlaying(false);
												setAdWatchedOrSkipped(true);
											}}
											className={skipButtonClasses}
										>
											<span
												className="inline-block w-[initial] align-middle text-xs font-medium text-white sm:text-sm"
												aria-label="Skip ad"
											>
												Skip Ad
												{!isSkippable && ` in ${secondsLeft}`}
											</span>
											<SkipIcon />
										</button>
									</div>
								</div>
							) : (
								<>{videoURL ? <VideoCard image={image} title={title} videoURL={videoURL} /> : null}</>
							)}
						</>
					) : (
						<div className="relative">
							<VideoCard image={image} title={title} videoURL={videoURL} />
							<div className={overlayClasses} onClick={() => setVideoAdPlaying(true)} />
						</div>
					)}
				</>
			) : (
				<>{videoURL ? <VideoCard image={image} title={title} videoURL={videoURL} /> : null}</>
			)}
		</>
	);
};

export default VideoAd;
